// swiperjs

// Initializing Swiper
const swiper = new Swiper('.section__featured--block', {
    // effect: "fade",
    slidesPerView: 2,
    spaceBetween: 50,
    // centeredSlides: true,
    loop: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    breakpoints: {
        100: {
            slidesPerView: 1,
        },
        1024: {
            slidesPerView: 2,
        },
        // 1200: {
        //     slidesPerView: 3,
        // },
    },
    speed: 1200,
})

const swiper2 = new Swiper('.section__featured--reviews', {
    slidesPerView: 1,
    spaceBetween: 100,
    autoplay: {
        delay: 3600,
        disableOnInteraction: true,
    },
    loop: true,
    pagination: {
        el: '.swiper-pagination2',
        clickable: true,
    },
    speed: 1600,
})