document.addEventListener('DOMContentLoaded', () => {
    const modal = document.querySelector(".modal");
    const modalContent = document.querySelector(".modal .modal-content");
    if (modal && modalContent) {
        modal.style.display = "flex";

        document.querySelector(".close-btn")?.addEventListener('click', () => modal.style.display = "none");

        window.addEventListener('click', (event) => {
            if (event.target == modal) {
                modal.style.display = "none";
            }
        });
    }
});