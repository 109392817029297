document.addEventListener('DOMContentLoaded', function() {
    window.addEventListener('scroll', function() {
        var announcementBar = document.getElementById('announcement-bar');
        var header = document.querySelector('.header');
        var menuTrigger = document.getElementById('menu-trigger');
        var scrollPosition = window.scrollY;

        if (scrollPosition >= 800) {
            announcementBar.classList.add('scrolling');
            header.classList.add('scrolling');
            menuTrigger.classList.add('scrolling');
        // } else {
        // remove announcement bar when scrolling up
        //     announcementBar.classList.remove('scrolling');
        //     header.classList.remove('scrolling');
        //     menuTrigger.classList.remove('scrolling');
        }
    });
});