/*
https://splidejs.com/guides/getting-started/
*/
import Splide from '@splidejs/splide';

const patientCases =  document.querySelectorAll('.gallery__cases__case');
if( patientCases ) {
    patientCases.forEach( (item) => {
        let patientsSlider = item.querySelector('.patients-slider');
        let thumbnailSlider = item.querySelector('.patients-thumbnail-slider');
        
        if( patientsSlider ) {
    
            var main = new Splide( patientsSlider, {
                type      : 'fade',
                rewind    : true,
                pagination: false,
                arrows    : true
            });
            
            var thumbnails = new Splide( thumbnailSlider, {
                fixedWidth  : 100,
                fixedHeight : 60,
                rewind      : true,
                pagination  : false,
                arrows    : false,
                isNavigation: true,
                breakpoints : {
                    600: {
                        fixedWidth : 60,
                        fixedHeight: 44,
                    },
                },
            });
            
            main.sync( thumbnails );
            main.mount();
            thumbnails.mount();
        }
    });
}